import React, {useContext, useEffect, useReducer, useState} from "react";

import openSocket from "../../services/socket-io";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit, Timer } from "@material-ui/icons";
import QueueModal from "../../components/QueueModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import {AuthContext} from "../../context/Auth/AuthContext";
import EscalaModal from '../../components/ConnectionModals/escalaModal'
import Tooltip from "@material-ui/core/Tooltip";
import Info from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex].name = queue.name;
      state[queueIndex].color = queue.color;
      state[queueIndex].greetingMessage = queue.greetingMessage;
      state[queueIndex].monday = queue.monday;
      state[queueIndex].tuesday = queue.tuesday;
      state[queueIndex].wednesday = queue.wednesday;
      state[queueIndex].thursday = queue.thursday;
      state[queueIndex].friday = queue.friday;
      state[queueIndex].saturday = queue.saturday;
      state[queueIndex].sunday = queue.sunday;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Queues = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [queueModalOpen, setQueueModalOpen] = useState(false);
  const [escalaOpen, setEscalaOpen] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/queue/"+user.company_id);
        dispatch({ type: "LOAD_QUEUES", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("queue"+user.company_id, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenQueueModal = () => {
    setQueueModalOpen(true);
    setSelectedQueue(null);
  };

  const handleCloseQueueModal = () => {
    setQueueModalOpen(false);
    setSelectedQueue(null);

  };

  const handleCloseEscalaModal = () => {
    setEscalaOpen(false);
    setSelectedQueue(null);
  };

  const handleEditEscala = (queue) => {
    setSelectedQueue(null);
    setTimeout(() => setSelectedQueue(queue),500)
    setEscalaOpen(true);
  };

  const handleEditQueue = (queue) => {
    setSelectedQueue(queue);
    setQueueModalOpen(true);
  };



  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      toast.success(i18n.t("Departamento deletado com sucesso!"));
      window.location.reload()
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${
            selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queues.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <QueueModal
        open={queueModalOpen}
        onClose={handleCloseQueueModal}
        queueId={selectedQueue?.id}
      />

      <EscalaModal
        open={escalaOpen}
        onClose={handleCloseEscalaModal}
        queue={selectedQueue}
      />
      <MainHeader>
{/*        <Title>{i18n.t("queues.title")}</Title>*/}
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenQueueModal}
          >
            Adicionar departamento
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("queues.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.color")}
              </TableCell>
              <TableCell align="center">
               Canal
              </TableCell>
           {/*   <TableCell align="center">
                {i18n.t("queues.table.greeting")}
              </TableCell>*/}
              <TableCell align="center">
                {i18n.t("queues.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {queues.map((queue) => (
                <TableRow key={queue.id}>
                  <TableCell align="center">{queue.name}</TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: queue.color,
                          width: 60,
                          height: 20,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                          style={{ width: 200, align: "center" }}
                          noWrap
                          variant="body2"
                      >
                        {queue.whatsapps[0] ? queue.whatsapps[0].name : '-'}
                      </Typography>
                    </div>
                  </TableCell>
                  {/*<TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {queue.greetingMessage}
                      </Typography>
                    </div>
                  </TableCell>*/}
                  <TableCell align="center">
                    <Tooltip title="Escala de Atendimento do departamento" >
                      <IconButton
                          size="small"
                          onClick={() => handleEditEscala(queue)}
                      >
                        <Timer />
                      </IconButton>
                    </Tooltip>

                    <Tooltip  title={queue.name !== 'Geral' ?
                        'Editar departamento' :
                        'Departamento padrão não edita!'} >
                      {queue.name !== 'Geral' ? (
                          <IconButton
                              size="small"
                              onClick={() => handleEditQueue(queue)}
                          >
                            <Edit />
                          </IconButton>
                      ):(
                          <IconButton
                              size="small"
                          >
                            <Edit style={{color:'#ccc'}}/>
                          </IconButton>
                      )}
                    </Tooltip>

                        <Tooltip
                            title={queue.name !== 'Geral' ?
                                'Excluir departamento' :
                                'Departamento padrão não exlcui!'}
                        >
                          {queue.name !== 'Geral' ? (
                              <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedQueue(queue);
                                    setConfirmModalOpen(true);
                                  }}
                              >
                                <DeleteOutline />
                              </IconButton>
                          ) : (
                              <IconButton
                                  size="small"
                              >
                                <DeleteOutline style={{color:'#ccc'}}/>
                              </IconButton>
                          )}

                        </Tooltip>

                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Queues;
