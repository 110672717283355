import React, {useContext, useEffect, useState} from "react";
import Logo1 from "../../assets/dermotos.png";
import Logo3 from "../../assets/logo1.png";

const Logo = ({type}) => {
    const [img, setImg] = useState('');

    useEffect(() => {
        async function setLogo(){
            if (window.location.hostname === 'chat.dermotos.com.br' ){
               setImg('dermotos')
            }else{
                setImg('r74');
            }
        }
        setLogo();
    }, []);

    return (
           <>
               {img === 'dermotos' && type === 'login' && (
                   <img src={Logo1} alt="" width={250} />
               )}
               {img === 'r74' && type === 'login' &&(
                   <img src={Logo3} alt="" width={250} />
               )}
               {img === 'dermotos' && type === 'menu' && (
                   <img src={Logo1} alt="" height={39} />
               )}
               {img === 'r74' && type === 'menu' &&(
                   <img src={Logo3} alt="" width={159} />
               )}
           </>
        )
}

export default Logo;